import React, { useEffect } from 'react'

const DownloadApp = () => {
  useEffect(() => {
    window.location.replace("afapp://splashscreen")
  }, [])

  return null
}

export default DownloadApp
